import React from "react";
import Button from "./button/Button";

export const Configureaza = ({ path }) => {
  const pathFirst = path.split("-")[0];
  const pathMobila = path.split("/")[1];
  const pathVerification =
    pathFirst === "/" ||
    pathFirst === "/usi" ||
    pathFirst === "/scari" ||
    pathFirst === "/contact" ||
    pathFirst === "/despre" ||
    pathMobila === "dressing" ||
    pathMobila === "bucatarii" ||
    pathMobila === "biblioteci" ||
    pathMobila === "alte-piese-de-mobilier";

  return (
    pathVerification && (
      <section
        id="footer-config"
        className={`${window.innerWidth < 385 ? "py-3" : "p-4"}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-sm-12 mx-auto text-center">
              <h1 className="text-warning">Configurator Scari</h1>
              <p className="my-2 px--sm-2 w-100 text-white">
                Ce este scara pentru tine?
              </p>
              <p className="my-2 px-sm-2 text-white w-100">
                Pentru noi scara este elementul care adauga valoare casei tale,
                structura care infrumuseteaza spatiul in care locuiesti.
              </p>
              <p className="my-2 px-sm-2 text-white w-100">
                Motivatia noastra este sa obtinem cel mai bun rezultat posibil,
                pentru fiecare situatie in parte. Tinem cont de dorintele
                clientului astfel incat sa cream o scara unica, adaptata
                spatiului disponibil si cerintelor individuale.
              </p>
              <Button
                buttonTitle="Configureaza Scara"
                link="/configurator-scari"
              />
            </div>
          </div>
        </div>
      </section>
    )
  );
};
