import { combineReducers } from "redux";
import productReducer from "./product";
import categoryReducer from "./category";
import alertReducer from "./alert";
import configurationReducer from "./configuration";

export default combineReducers({
  product: productReducer,
  category: categoryReducer,
  alert: alertReducer,
  configuration: configurationReducer,
});
