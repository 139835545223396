import { CONFIGURATOR_ERROR, GET_CONFIG_IMAGES } from "../actions/types";

const initialState = {
  configImages: [],
  loading: true,
  errors: [],
};

const configutationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CONFIG_IMAGES:
      return {
        ...state,
        configImages: payload,
        loading: false,
      };
    case CONFIGURATOR_ERROR:
      return {
        ...state,
        errors: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default configutationReducer;
