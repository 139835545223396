import axios from "axios";
import { setAlert } from "./alert";
import { ADD_MESSAGE, MESSAGE_ERROR } from "./types";

export const addMessage = ({ nume, email, mesaj }, history) => async (
  dispatch
) => {
  const config = { headers: { "Content-Type": "application/json" } };
  const body = JSON.stringify({ nume, email, mesaj });

  try {
    const res = await axios.post("/contact/message", body, config);
    dispatch({ type: ADD_MESSAGE, payload: res.data });
    dispatch(setAlert("Mesajul a fost inregistrat. Va multumim!", "success"));
    history.push("/");
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({ type: MESSAGE_ERROR });
  }
};
