import axios from "axios";
import { loadProgressBar } from "axios-progress-bar";
import { setAlert } from "./alert";
import { ADD_CONFIGURATOR, CONFIGURATOR_ERROR } from "./types";

export const addConfigurator = (formData, history, onProgress) => async (
  dispatch
) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
    onProgress,
  };
  try {
    loadProgressBar();
    const res = await axios.post("/configuration", formData, config);
    dispatch({ type: ADD_CONFIGURATOR, payload: res.data });
    dispatch(setAlert("Configurare trimisa cu succes!", "success"));
    history.push("/");
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({ type: CONFIGURATOR_ERROR });
  }
};
