import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Alert = ({ alerts }) =>
  alerts.length > 0 && (
    <div className={`alert alert-${alerts[0].alertType} moveInBottom`}>
      {alerts && alerts.length > 0 ? alerts[0].msg : null}
    </div>
  );
//success or danger

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
