import React from "react";
import PropTypes from "prop-types";

const Atasament = ({ onChange, photo, name }) => {
  return (
    <div className="row">
      <div className="input-group mb-3 col-10 col-md-6 text-left ml-md-4 mx-auto">
        <div className="custom-file">
          <input
            type="file"
            name={name}
            className="custom-file-input"
            id="customFileLangHTML"
            accept="image/*"
            onChange={(e) => onChange(e)}
          />
          <label className="custom-file-label">
            {photo && photo.name ? photo.name : "Ataseaza un fiser"}
          </label>
        </div>
      </div>
    </div>
  );
};

Atasament.propTypes = {
  onChange: PropTypes.func.isRequired,
  photo: PropTypes.object,
  name: PropTypes.string.isRequired,
};

export default Atasament;
