import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import SectionTitle from "../products/SectionTitle";
import Button from "../layout/button/Button";
import Spinner from "../layout/Spinner";
import ImageSelector from "./ImageSelector";
import { getConfigImages } from "../../store/actions/configImage";
import { connect } from "react-redux";
import { addConfigurator } from "../../store/actions/stairConfig";
import { setAlert } from "../../store/actions/alert";
import Atasament from "./Atasament";
import "axios-progress-bar/dist/nprogress.css";
import { Link } from "react-router-dom";

const StairConfigurator = ({
  configuration: { loading },
  getConfigImages,
  addConfigurator,
  setAlert,
  history,
}) => {
  const [inputs, setFormData] = useState({
    tipStructura: "",
    tipScara: "",
    ht: "",
    gp: "",
    lungimeGolDecupat: "",
    latimeGolDecupat: "",
    tipVang: "",
    tipContratreapta: "",
    tipBalustrada: "",
    perioadaMontaj: "",
    specieLemnoasa: "",
    photo1: {},
    photo2: {},
    photo3: {},
    locatie: "",
    numeSolicitant: "",
    telefon: "",
    email: "",
    detalii: "",
    verification: "",
    gdpr: false,
    next: false,
    formData: new FormData(),
  });

  useEffect(() => {
    getConfigImages("stair");
  }, [getConfigImages]);

  const {
    tipStructura,
    tipScara,
    ht,
    gp,
    lungimeGolDecupat,
    latimeGolDecupat,
    tipVang,
    tipContratreapta,
    tipBalustrada,
    specieLemnoasa,
    photo1,
    photo2,
    photo3,
    locatie,
    numeSolicitant,
    telefon,
    email,
    detalii,
    verification,
    gdpr,
    next,
    formData,
  } = inputs;

  const onChange = (e) => {
    const value = e.target.name.match(/photo[123]/g)
      ? e.target.files[0]
      : e.target.value;
    e.target.value !== "verification" &&
      e.target.value !== "gdpr" &&
      formData.set(e.target.name, value);
    e.target.name === "gdpr"
      ? setFormData({ ...inputs, [e.target.name]: e.target.checked })
      : setFormData({ ...inputs, [e.target.name]: value });
  };
  const onSelectedPhotoChange = (attribute, value) => {
    setFormData({ ...inputs, [attribute]: value });
    formData.set(attribute, value);
  };

  const nextStep = () => {
    if (tipStructura) setFormData({ ...inputs, next: !next });
    else setAlert("Selectati tipul de structura dorit", "danger");
  };

  const onProgress = (event) => {
    console.log("PROGRESSSSSS");
    console.log(event.loaded);
    console.log(event.total);
    // setProgress(Math.round((100 * event.loaded) / event.total));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    verification === "4" && gdpr
      ? addConfigurator(formData, history, onProgress)
      : setAlert("Completati campurile marcate cu *", "danger");
  };

  return loading ? (
    <Spinner />
  ) : (
    <section id="configurator" className="configurator bg-white py-5 mb-5">
      <div className="container mb-5 py-2">
        <SectionTitle
          sectionTitle="Configurator scari interioare"
          sectionSubtitle="Configureaza-ti scara"
        />
        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Acest{" "}
          <i>
            <strong>configurator scari interioare</strong>
          </i>{" "}
          contine toate detaliile necesare, pentru ca noi sa intelegem cat mai
          bine specificul acestui proiect pentru scara dvs. Daca aveti nevoie de{" "}
          <i>
            <strong>proiectare, executie si montaj scari interioare</strong>
          </i>{" "}
          pe vanguri din lemn si metal sau scari din beton placate cu lemn,
          completati campurile acestui configurator, iar noi va stam la
          dispozitie pentru realizarea dorintelor dvs.
        </div>

        {!next ? (
          <Fragment>
            <div className="col-12 text-left">
              <h3 className="my-3">Alegeti tipul de structura dorit</h3>
            </div>
            <ImageSelector
              onSelectedPhotoChange={onSelectedPhotoChange}
              tipSelectie="tipStructura"
              stateValue={tipStructura}
            />
            <div className="col-12 text-center">
              <button
                className="btn col-8 mx-auto col-md-6 my-4 btn-cherry-yellow text-uppercase"
                onClick={nextStep}
              >
                Mai departe
              </button>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="col-12 text-center">
              <button
                className="btn col-10 col-md-8 mx-auto col-md-6 my-4 btn-cherry-yellow text-uppercase"
                onClick={nextStep}
              >
                Inapoi la selectia tipului de structura
              </button>
            </div>

            <form onSubmit={(e) => onSubmit(e)}>
              {tipStructura === "Scara pe vanguri" && (
                <Fragment>
                  <div className="row">
                    <div className="col-12 text-left">
                      <h3 className="my-3">1. Tipul scarii</h3>
                    </div>
                  </div>
                  <ImageSelector
                    onSelectedPhotoChange={onSelectedPhotoChange}
                    tipSelectie="tipScara"
                    stateValue={tipScara}
                  />
                  <div className="row">
                    <div className="col-12 text-left">
                      <h3 className="my-3">
                        2. Ht - Inaltime totala intre etaj si mansarda
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-10 col-md-6 ml-md-4 mx-auto">
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="milimetri"
                          name="ht"
                          value={ht}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-left">
                      <h3 className="my-3">3. Gp - Grosimea placii de sus</h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-10 col-md-6 ml-md-4 mx-auto">
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="milimetri"
                          name="gp"
                          value={gp}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-left">
                      <h3 className="my-3">4. Dimensiunile golului decupat</h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-10 col-md-6 ml-md-4 mx-auto">
                      <div className="form-group mb-3">
                        <label>Lungime</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="milimetri"
                          name="lungimeGolDecupat"
                          value={lungimeGolDecupat}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label>Latime</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="milimetri"
                          name="latimeGolDecupat"
                          value={latimeGolDecupat}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-left">
                      <h3 className="my-3">5. Tip de vang</h3>
                    </div>
                  </div>
                  <ImageSelector
                    onSelectedPhotoChange={onSelectedPhotoChange}
                    tipSelectie="tipVang"
                    stateValue={tipVang}
                  />
                  <div className="row">
                    <div className="col-12 text-left">
                      <h3 className="my-3">6. Contratreapta</h3>
                    </div>
                  </div>
                  <ImageSelector
                    onSelectedPhotoChange={onSelectedPhotoChange}
                    tipSelectie="tipContratreapta"
                    stateValue={tipContratreapta}
                  />
                </Fragment>
              )}
              <div className="row">
                <div className="col-12 text-left">
                  <h3 className="my-3">
                    {tipStructura === "Scara din beton placata cu lemn" ? 1 : 7}
                    . Balustrada
                  </h3>
                </div>
              </div>
              <ImageSelector
                onSelectedPhotoChange={onSelectedPhotoChange}
                tipSelectie="tipBalustrada"
                stateValue={tipBalustrada}
                beton={tipStructura === "Scara din beton placata cu lemn"}
              />
              <div className="row">
                <div className="col-12 text-left">
                  <h3 className="my-3">
                    {tipStructura === "Scara din beton placata cu lemn" ? 2 : 8}
                    . Perioada de montaj dorita
                  </h3>
                </div>
              </div>
              <div className="row">
                <fieldset className="ml-5" onChange={(e) => onChange(e)}>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="perioadaMontaj"
                      value="urgent - 2 saptamani"
                    />
                    <label className="form-check-label">
                      urgent - 2 saptamani
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="perioadaMontaj"
                      value="< 3 luni"
                    />
                    <label className="form-check-label">
                      timp de lucru &lt; 3 luni
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="perioadaMontaj"
                      value="> 3 luni"
                    />
                    <label className="form-check-label">
                      timp de lucru &gt; 3 luni
                    </label>
                  </div>
                </fieldset>
              </div>
              <div className="row">
                <div className="col-12 text-left">
                  <h3 className="my-3">
                    {tipStructura === "Scara din beton placata cu lemn" ? 3 : 9}
                    . Specie lemnoasa
                  </h3>
                </div>
              </div>
              <ImageSelector
                onSelectedPhotoChange={onSelectedPhotoChange}
                tipSelectie="specieLemnoasa"
                stateValue={specieLemnoasa}
                beton={tipStructura === "Scara din beton placata cu lemn"}
              />
              <div className="row">
                <div className="col-12 text-left">
                  <h3 className="my-3">
                    {tipStructura === "Scara din beton placata cu lemn"
                      ? 4
                      : 10}
                    . Trimite fotografii / schite cu locul unde se monteaza
                    scara (maxim 3 imagini / maxim 3MB fiecare)
                  </h3>
                  <small>*jpg / jpeg / png</small>
                </div>
              </div>
              <Atasament onChange={onChange} photo={photo1} name="photo1" />
              <Atasament onChange={onChange} photo={photo2} name="photo2" />
              <Atasament onChange={onChange} photo={photo3} name="photo3" />
              <div className="row">
                <div className="col-12 text-left">
                  <h3 className="my-3">
                    {tipStructura === "Scara din beton placata cu lemn"
                      ? 5
                      : 11}
                    . Locatia unde se doreste montajul scarii{" "}
                    <span className="text-danger">*</span>
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-10 col-md-6 ml-md-4 mx-auto">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Ex: Bucuresti, Sector 3 (Oras, comuna, sat - daca e cazul)"
                      name="locatie"
                      value={locatie}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-left">
                  <h3 className="my-3">
                    {tipStructura === "Scara din beton placata cu lemn"
                      ? 6
                      : 12}
                    . Nume Solicitant <span className="text-danger">*</span>
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-10 col-md-6 ml-md-4 mx-auto">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Numele dvs"
                      name="numeSolicitant"
                      value={numeSolicitant}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-left">
                  <h3 className="my-3">
                    {tipStructura === "Scara din beton placata cu lemn"
                      ? 7
                      : 13}
                    . Telefon <span className="text-danger">*</span>
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-10 col-md-6 ml-md-4 mx-auto">
                  <div className="form-group">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="07xx xxx xxx"
                      name="telefon"
                      value={telefon}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-left">
                  <h3 className="my-3">
                    {tipStructura === "Scara din beton placata cu lemn"
                      ? 8
                      : 14}
                    . Email
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-10 col-md-6 ml-md-4 mx-auto">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="exemplu@domeniu.com"
                      name="email"
                      value={email}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-left">
                  <h3 className="my-3">
                    {tipStructura === "Scara din beton placata cu lemn"
                      ? 9
                      : 15}
                    . Alte detalii
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-10 col-md-6 ml-md-4 mx-auto">
                  <div className="form-group">
                    <textarea
                      name="detalii"
                      cols="30"
                      rows="8"
                      className="form-control"
                      placeholder="Alte detalii pe care doriti sa ni le transmiteti..."
                      value={detalii}
                      onChange={(e) => onChange(e)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-left">
                  <h3 className="my-3">
                    {tipStructura === "Scara din beton placata cu lemn"
                      ? 10
                      : 16}
                    . Verificare <span className="text-danger">*</span>
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-10 col-md-6 ml-md-4 mx-auto">
                  <div className="form-group">
                    <input
                      type="text"
                      name="verification"
                      className="form-control"
                      placeholder="2 + 2 = ?"
                      value={verification}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-10 col-md-6 ml-md-5 mx-auto">
                  <div className="form-group">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={gdpr}
                      name="gdpr"
                      onChange={(e) => onChange(e)}
                    />
                    <h5 className="form-check-label">
                      Sunt de acord cu{" "}
                      <Link
                        to="/politica-de-confidentialitate"
                        className="article-link"
                      >
                        prelucrarea datelor personale - GDPR
                      </Link>
                      <span className="text-danger">*</span>
                    </h5>
                  </div>
                </div>
              </div>
              <span className="text-danger">*</span> obligatoriu
              <Button buttonTitle="Trimite configurarea" />
            </form>
          </Fragment>
        )}
      </div>
    </section>
  );
};

StairConfigurator.propTypes = {
  configuration: PropTypes.object.isRequired,
  getConfigImages: PropTypes.func.isRequired,
  addConfigurator: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({
  configuration: state.configuration,
});

export default connect(mapStateToProps, {
  getConfigImages,
  addConfigurator,
  setAlert,
})(StairConfigurator);
