import React from "react";

export const WhatsApp = () => {
  return (
    <div className="whatsapp-button">
      <a
        href="https://wa.link/ann60n"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="http://www.proiectarescari.com/configPhoto/5f6357d44445233b4ce88f21"
          alt="WhatsApp"
        />
      </a>
    </div>
  );
};
