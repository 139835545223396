import React, { useState } from "react";
import PropTypes from "prop-types";
import SectionTitle from "./products/SectionTitle";
import Button from "./layout/button/Button";
import { addMessage } from "../store/actions/message";
import { connect } from "react-redux";
import { setAlert } from "../store/actions/alert";
import {
  FaUser,
  FaEnvelope,
  FaLock,
  FaPhone,
  FaMap,
  FaClock,
  FaWhatsapp,
  FaFacebookF,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Contact = ({ addMessage, setAlert, history }) => {
  const [formData, setFormData] = useState({
    nume: "",
    email: "",
    mesaj: "",
    verification: "",
    gdpr: false,
  });
  const { nume, email, mesaj, verification, gdpr } = formData;

  const onSubmit = (e) => {
    e.preventDefault();
    verification === "3" && gdpr
      ? addMessage({ nume, email, mesaj, verification }, history)
      : setAlert("Toate campurile sunt obligatorii", "danger");
  };

  const onChange = (e) => {
    e.target.name === "gdpr"
      ? setFormData({ ...formData, [e.target.name]: e.target.checked })
      : setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="container mb-5 contact-wrap">
      <SectionTitle
        sectionTitle="Contact"
        sectionSubtitle="Scrie un mesaj pentru proiectare scari"
      />
      <div className="row">
        <div className="mx-auto contact-background p py-3 col-10 col-md-5 col-lg-5 my-2">
          <div className="my-4">
            <FaMap className="cherry-link m-2 contact" />
            &nbsp; Str. Egretei, Nr. 6, Bl. B6, Ap. 63, Biroul 1, Brașov
          </div>
          <div className="my-4">
            <FaPhone className="cherry-link m-2 contact" />
            &nbsp; (004) 0745 258 841
          </div>
          <div className="my-4">
            <FaEnvelope className="cherry-link m-2 contact" />
            &nbsp; proiectarescari@yahoo.com
          </div>
          <div className="my-4">
            <FaClock className="cherry-link m-2 contact" />
            &nbsp; L-V 08:00-18:00 <span className="cherry-link">|</span> S
            08:00-14:00 <span className="cherry-link">|</span> D Inchis
          </div>
          <div className="my-4">
            <a
              href="https://wa.link/ann60n"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp className="cherry-link m-2 social wa" />
              &nbsp;WhatsApp
            </a>
          </div>
          <div className="my-4">
            <a
              href="https://www.facebook.com/proiectarescari"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF className="cherry-link m-2 social fb" /> Facebook
            </a>
          </div>
        </div>
        <form
          onSubmit={(e) => onSubmit(e)}
          className="mx-auto contact-background p-3 col-10 col-md-5 col-lg-5 my-2"
        >
          <div className="row">
            <div className="col-12 mx-auto">
              <div className="input-group my-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <FaUser />
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name="nume"
                  placeholder="Nume"
                  value={nume}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mx-auto">
              <div className="input-group my-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <FaEnvelope />
                  </span>
                </div>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mx-auto">
              <div className="input-group my-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <FaLock />
                  </span>
                </div>
                <input
                  type="text"
                  name="verification"
                  className="form-control"
                  placeholder="1 + 2 = ?"
                  value={verification}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mx-auto">
              <div className="form-group my-2">
                <textarea
                  name="mesaj"
                  cols="30"
                  rows="5"
                  className="form-control"
                  placeholder="Mesaj..."
                  value={mesaj}
                  onChange={(e) => onChange(e)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-11 ml-md-4 mx-auto">
              <div className="form-group">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={gdpr}
                  name="gdpr"
                  onChange={(e) => onChange(e)}
                />
                <h5 className="form-check-label">
                  Sunt de acord cu{" "}
                  <Link
                    to="/politica-de-confidentialitate"
                    className="article-link"
                  >
                    prelucrarea datelor personale - GDPR
                  </Link>
                  <span className="text-danger">*</span>
                </h5>
              </div>
            </div>
          </div>

          <Button buttonTitle="Trimite" />
        </form>
      </div>
    </div>
  );
};

Contact.propTypes = {
  addMessage: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  history: PropTypes.object,
};

export default connect(null, { addMessage, setAlert })(Contact);
