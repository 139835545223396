import axios from "axios";
import {
  PRODUCT_ERROR,
  GET_PRODUCTS_CAT_SUBCAT,
  GET_ALL_PRODUCTS,
} from "./types";

export const getAllProducts = () => async (dispatch) => {
  try {
    const response = await axios.get("/products/home");
    dispatch({
      type: GET_ALL_PRODUCTS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const getProductsByCatAndSubcat = (category, subcategory) => async (
  dispatch
) => {
  try {
    if (category && subcategory) {
      const response = await axios.get(
        `/products/${category._id}/${subcategory._id}`
      );
      dispatch({
        type: GET_PRODUCTS_CAT_SUBCAT,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PRODUCT_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
