import React from "react";
import { BiLink } from "react-icons/bi";
import SectionTitle from "./products/SectionTitle";
import { API } from "../config";

export const Despre = () => {
  return (
    <div className="container my-5">
      <SectionTitle sectionTitle="Despre noi" />
      <div className="my-5 row">
        <div className="col-11 col-sm-8 col-md-5 col-lg-5 mx-auto my-3 ">
          <img
            src={`${API}/configPhoto/6002eccfcd9514347051660e`}
            alt={"Despre"}
            className="picture img-fluid"
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 mx-auto text-justify despre">
          <br />
          <p>
            Oferim servicii profesionale de proiectare 3D a scarilor interioare
            din lemn, dar si posibilitatea de realizare a scarilor interioare
            din lemn prin colaborarea cu ateliere de tamplarie specializate in
            productia scarilor interioare din lemn.
          </p>
          <p>
            Ne-am bazat intotdeauna pe aplicarea cunostintelor invatate in
            domeniul industriei lemnului astfel incat sa facem fiecare scara{" "}
            <i>unica</i>.
          </p>
          <p>
            Sfaturi de specialitate pentru scările interioare din lemn:
            <a
              href="https://www.daibau.ro/articol/333/scara_interioara_-_design_interior_modern#antohe-ionel-intreprindere-individuala-srl"
              target="_blank"
              rel="noopener noreferrer"
              className="article-link m-2"
            >
              Click Aici
              <BiLink />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
