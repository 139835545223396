import axios from "axios";
import {
  ADD_CONFIG_IMAGE,
  CONFIGURATOR_ERROR,
  GET_CONFIG_IMAGES,
} from "./types";

export const addConfigImage = ({
  tipProdus,
  tipSelectie,
  titlu,
  photo,
}) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  const body = JSON.stringify({
    tipProdus,
    tipSelectie,
    titlu,
    photo,
  });
  try {
    const res = await axios.post("/configuration/photos", body, config);
    dispatch({ type: ADD_CONFIG_IMAGE, payload: res.data });
  } catch (error) {
    dispatch({
      type: CONFIGURATOR_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const getConfigImages = (type) => async (dispatch) => {
  try {
    const res = await axios.get(`/configuration/photos/${type}`);
    dispatch({ type: GET_CONFIG_IMAGES, payload: res.data });
  } catch (error) {
    dispatch({
      type: CONFIGURATOR_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
