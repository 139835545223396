import axios from "axios";
import { GET_CATEGORIES, PRODUCT_ERROR } from "./types";

export const getCatAndSubcat = () => async (dispatch) => {
  try {
    const categories = await axios.get(`/categories`);
    const subcategories = await axios.get(`/subcategories`);
    const types = [...new Set(categories.data.map((cat) => cat.type))];

    ////////////////////////////////////////////////////////////
    let categoriesByTypes = {};
    types.map(
      (type) =>
        (categoriesByTypes[type] = categories.data
          .filter((category) => category.type === type)
          .reduce((o, key) => ({ ...o, [key.name]: [] }), {}))
    );

    subcategories.data.forEach((subcategory, i) => {
      let category = categories.data.find(
        (category) => category._id === subcategory.category
      ).name;
      types.forEach(
        (type) =>
          categoriesByTypes[type][category] &&
          categoriesByTypes[type][category].push(subcategory.name)
      );
    });

    ///////////////////////////////////////////////////////////
    const stairCategories = categories.data
      .filter((category) => category.type === "scari interioare din lemn")
      .reduce((o, key) => ({ ...o, [key.name]: [] }), {});

    subcategories.data.forEach((subcategory, i) => {
      let category = categories.data.find(
        (category) => category._id === subcategory.category
      ).name;
      stairCategories[category] &&
        stairCategories[category].push(subcategory.name);
    });

    const doorCategories = categories.data
      .filter((category) => category.type === "usi din lemn")
      .reduce((o, key) => ({ ...o, [key.name]: [] }), {});

    subcategories.data.forEach((subcategory, i) => {
      let category = categories.data.find(
        (category) => category._id === subcategory.category
      ).name;
      doorCategories[category] &&
        doorCategories[category].push(subcategory.name);
    });

    const mobilaCategories = categories.data
      .filter((category) => category.type === "mobilier din lemn")
      .reduce((o, key) => ({ ...o, [key.name]: [] }), {});
    subcategories.data.forEach((subcategory, i) => {
      let category = categories.data.find(
        (category) => category._id === subcategory.category
      ).name;
      mobilaCategories[category] &&
        mobilaCategories[category].push(subcategory.name);
    });

    dispatch({
      type: GET_CATEGORIES,
      payload: {
        stairCategories,
        doorCategories,
        mobilaCategories,
        categoriesByTypes,
        categories: categories.data,
        subcategories: subcategories.data,
        types,
      },
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
