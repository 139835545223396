import React from "react";
import { API } from "../../config";
import Button from "../layout/button/Button";
import PropTypes from "prop-types";
import { FaTree } from "react-icons/fa";

const StairCard = ({
  stairTitle,
  item,
  config,
  onClickData,
  selected,
  index,
}) => {
  return (
    <div
      className={`col-12 col-sm-10 col-md-6 col-lg-4 text-center my-4 mx-auto`}
      data-item="sweet"
      onClick={onClickData}
    >
      <div className={`card single-item `}>
        <div className="img-container">
          <img
            src={`${API}/${config ? "configPhoto" : "photo"}/${item._id}`}
            alt={item.name ? item.name : item.title}
            className="card-img-top stairs-img img-fluid"
          />
        </div>
        <div className="card-body">
          <h5 id="stairs-item-name card-title text-capitalize">
            {index ? `${index}. ${stairTitle}` : stairTitle}
          </h5>
          {config && (
            <Button
              buttonTitle={selected ? "selectat" : "selecteaza"}
              selected={selected}
              config={true}
            />
          )}
        </div>
        {
          //item.pret &&
          item.lemn && (
            <div className="product-details">
              <p>
                <FaTree /> {item.lemn}
              </p>
              {/* <p>{item.pret} RON</p> */}
            </div>
          )
        }
      </div>
    </div>
  );
};

StairCard.propTypes = {
  stairTitle: PropTypes.string,
  item: PropTypes.object,
  config: PropTypes.bool,
  onClickData: PropTypes.func,
  selected: PropTypes.bool,
  index: PropTypes.number,
};

export default StairCard;
