import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../home/Home";
import NotFound from "../layout/NotFound";
import Product from "../products/Product";
import StairConfigurator from "../configurator/StairConfigurator";
import Contact from "../Contact";
import { Configureaza } from "../layout/Configureaza";
import { Footer } from "../layout/Footer";
import { Despre } from "../Despre";
import { PoliticaConfidentialitate } from "../layout/PoliticaConfidentialitate";
import { PoliticaCookies } from "../layout/PoliticaCookies";
import { WhatsApp } from "../layout/WhatsApp";

const Routes = (props) => {
  return (
    <Fragment>
      <section className="container-fluid">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/:categorie/:subcategorie" component={Product} />
          <Route
            exact
            path="/configurator-scari"
            component={StairConfigurator}
          />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/despre" component={Despre} />
          <Route
            exact
            path="/politica-de-confidentialitate"
            component={PoliticaConfidentialitate}
          />
          <Route exact path="/politica-cookies" component={PoliticaCookies} />
          <Route component={NotFound} />
        </Switch>
      </section>
      <Configureaza path={props.location.pathname} />
      <Footer />
      <WhatsApp />
    </Fragment>
  );
};

export default Routes;
