import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import Button from "./button/Button";

const NotFound = () => {
  return (
    <div className="col-8 mx-auto my-5 text-center">
      <h2 className="text-primary">
        <FaExclamationTriangle /> Pagina negasita
      </h2>
      <h3>Pagina pe care o cauti nu exista</h3>
      <div>
        <Button buttonTitle="Acasa" link="/" />
      </div>
    </div>
  );
};

export default NotFound;
