import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { BsCheck } from "react-icons/bs";

import "./Button.css";

const Button = ({ buttonTitle, link, config, selected }) => {
  return (
    <Fragment>
      {link ? (
        <Link
          to={link}
          className="btn col-10 mx-auto col-md-6 my-4 btn-cherry-yellow text-uppercase btn-block"
        >
          {buttonTitle}
        </Link>
      ) : config ? (
        <div className="buttonCheckWrapper">
          <input
            type="button"
            className={`btn   ${
              selected ? "btn-blue" : "btn-cherry-yellow"
            } text-uppercase m-1`}
            value={`${buttonTitle}`}
          />
          {selected && (
            <BsCheck
              style={{
                color: "var(--mainBlue)",
                fontSize: "20px",
                fontWeight: "600"
              }}
            />
          )}
        </div>
      ) : (
        <input
          type="submit"
          value={buttonTitle}
          className="btn col-10 mx-auto col-md-6 my-4 btn-cherry-yellow text-uppercase btn-block"
        />
      )}
    </Fragment>
  );
};

export default Button;
