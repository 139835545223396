import React, { Component } from "react";
import { connect } from "react-redux";
// import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { FaPhone, FaBars } from "react-icons/fa";
import { getCatAndSubcat } from "../../store/actions/category";
import { NavbarWrapper } from "./Navbar.style";
import Logo from "../../img/logo/proiectare-scari.svg";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.stairCategories = React.createRef();
    this.doorCategories = React.createRef();
    this.mobilaCategories = React.createRef();
    this.categoriesByTypes = React.createRef();
    this.isActive = (history, path) => {
      if (history.location.pathname.split("-")[0] === path) {
        return { color: "#f7ba15" };
      } else {
        return { color: "#ffffff" };
      }
    };
    this.isMobilaActive = (history) => {
      const actualPath = history.location.pathname.split("/")[1];
      const expectedPaths = [
        "dressing",
        "bucatarii",
        "biblioteci",
        "alte-piese-de-mobilier",
      ];
      let match = false;
      expectedPaths.forEach((path) => {
        if (path === actualPath) match = true;
      });
      return match ? { color: "#f7ba15" } : { color: "#ffffff" };
    };
    this.state = {
      showStairs: false,
      showDoors: false,
      showMobila: false,
      mainMenu: false,
    };
  }

  componentDidMount() {
    this.props.getCatAndSubcat();
  }
  enterStairs = () => {
    this.setState({ showStairs: true, showDoors: false, showMobila: false });
  };
  leaveStairs = () => {
    this.setState({ showStairs: false });
  };
  enterDoors = () => {
    this.setState({ showDoors: true, showStairs: false, showMobila: false });
  };
  leaveDoors = () => {
    this.setState({ showDoors: false });
  };
  enterMobila = () => {
    this.setState({ showDoors: false, showStairs: false, showMobila: true });
  };
  leaveMobila = () => {
    this.setState({ showMobila: false });
  };
  leave = () => {
    this.setState({ showStairs: false, showDoors: false, showMobila: false });
  };
  toggleDropdownStairs = () => {
    this.setState({ showStairs: !this.state.showStairs });
  };
  toggleDropdownDoors = () => {
    this.setState({ showDoors: !this.state.showDoors });
  };
  toggleDropdownMobila = () => {
    this.setState({ showMobila: !this.state.showMobila });
  };
  toggleMainMenu = () => {
    this.setState({ mainMenu: !this.state.mainMenu });
  };
  render() {
    const headerMenu = (
      <div className="collapse navbar-collapse main-menu" id="myNavbar">
        <ul className="navbar-nav text-capitalize mx-auto">
          <li className="nav-item">
            <Link
              to="/"
              className="nav-link"
              onMouseEnter={this.leave}
              style={this.isActive(this.props.history, "/")}
              onClick={this.toggleMainMenu}
            >
              Acasa
            </Link>
          </li>
          <li
            className={`dropdown nav-item ${
              this.state.showStairs ? "show" : ""
            }`}
            ref={(dropdown) => (this.dropdown = dropdown)}
            onClick={this.toggleDropdownStairs}
            onMouseEnter={() =>
              window.innerWidth > 767 ? this.enterStairs() : undefined
            }
            onMouseLeave={() => {
              this.leaveDoors();
              this.leaveMobila();
            }}
          >
            <p
              className="nav-link dropdown-toggle pointer"
              data-toggle="dropdown"
              id="dropdownMenuButton"
              aria-haspopup="true"
              aria-expanded={this.state.showStairs}
              style={this.isActive(this.props.history, "/scari")}
            >
              Scari{" "}
            </p>
          </li>
          <li
            className={`dropdown nav-item ${
              this.state.showDoors ? "show" : ""
            }`}
            ref={(dropdown) => (this.dropdown = dropdown)}
            onClick={this.toggleDropdownDoors}
            onMouseEnter={() =>
              window.innerWidth > 767 ? this.enterDoors() : undefined
            }
            onMouseLeave={() => {
              this.leaveStairs();
              this.leaveMobila();
              //window.innerWidth < 767 && this.leaveDoors();
            }}
          >
            <p
              className="nav-link dropdown-toggle pointer"
              data-toggle="dropdown"
              id="dropdownDoors"
              aria-haspopup="true"
              aria-expanded={this.state.showDoors}
              style={this.isActive(this.props.history, "/usi")}
            >
              Usi
            </p>
          </li>
          <li
            className={`dropdown nav-item ${
              this.state.showMobila ? "show" : ""
            }`}
            ref={(dropdown) => (this.dropdown = dropdown)}
            onClick={this.toggleDropdownMobila}
            onMouseEnter={() =>
              window.innerWidth > 767 ? this.enterMobila() : undefined
            }
            onMouseLeave={() => {
              this.leaveStairs();
              this.leaveDoors();
            }}
          >
            <p
              className="nav-link dropdown-toggle pointer"
              data-toggle="dropdown"
              id="dropdownMobila"
              aria-haspopup="true"
              aria-expanded={this.state.showMobila}
              style={this.isMobilaActive(this.props.history)}
            >
              Mobilier
            </p>
          </li>
          <li className="nav-item">
            <Link
              to="/configurator-scari"
              className="nav-link"
              onMouseEnter={this.leave}
              onClick={this.toggleMainMenu}
              style={this.isActive(this.props.history, "/configurator")}
            >
              Configurator
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/despre"
              className="nav-link"
              onMouseEnter={this.leave}
              onClick={this.toggleMainMenu}
              style={this.isActive(this.props.history, "/despre")}
            >
              Despre
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/contact"
              className="nav-link"
              onMouseEnter={this.leave}
              onClick={this.toggleMainMenu}
              style={this.isActive(this.props.history, "/contact")}
            >
              Contact
            </Link>
          </li>
        </ul>
        <div className="nav-info-items">
          <div className="nav-info align-items-center d-none d-md-flex justify-content-between mx">
            <p className="text-white mx-4 mb-0">
              <span className="mx-2 phone-icon">
                <FaPhone className="nav-icon fa-fw" />
              </span>
              0745 258 841
            </p>
          </div>
        </div>
      </div>
    );
    return (
      <NavbarWrapper
        id="header"
        showStairs={this.state.showStairs}
        showDoors={this.state.showDoors}
        showMobila={this.state.showMobila}
        mainMenu={this.state.mainMenu}
      >
        <nav
          className="navbar navbar-expand-md px-4"
          onClick={() => {
            (this.state.showMobila ||
              this.state.showDoors ||
              this.state.showStairs) &&
              this.leave();
          }}
        >
          <div className="logo">
            <Link to="/" className="navbar-brand" title="Proiectare Scari">
              <img src={Logo} className="img-fluid" alt="Proiectare Scari" />
            </Link>
          </div>
          <button
            type="button"
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#myNavbar"
          >
            <span
              className="toggler-icon"
              onClick={() => {
                this.toggleMainMenu();
                this.leave();
              }}
            >
              <FaBars className="nav-icon fa-fw" />
            </span>
          </button>
          {headerMenu}
        </nav>
        {this.props.stairCategories[
          Object.keys(this.props.stairCategories)[0]
        ] && (
          <div
            className="dropdown-menu container-fluid stairs"
            onMouseLeave={this.leave}
            aria-labelledby="dropdownMenuButton"
          >
            <div className="row">
              {Object.keys(this.props.stairCategories).map((cat, i) =>
                i === 1 ? (
                  <li className="col-sm-3" key={i}>
                    <ul>
                      <li className="dropdown-header">{cat}</li>
                      {this.props.stairCategories[cat].map((subcat, i) => (
                        <li key={i}>
                          <Link
                            to={`/${cat
                              .toLowerCase()
                              .split(" ")
                              .join("-")}/${subcat
                              .toLowerCase()
                              .split(" ")
                              .join("-")}`}
                            onClick={() => {
                              this.leave();
                              this.toggleMainMenu();
                            }}
                          >
                            {subcat}
                          </Link>
                        </li>
                      ))}
                      <li className="divider"></li>
                      <li className="dropdown-header">
                        {Object.keys(this.props.stairCategories)[2]}
                      </li>
                      {this.props.stairCategories[
                        Object.keys(this.props.stairCategories)[2]
                      ].map((subcat, i) => (
                        <li key={i}>
                          <Link
                            to={`/${Object.keys(this.props.stairCategories)[2]
                              .toLowerCase()
                              .split(" ")
                              .join("-")}/${subcat
                              .toLowerCase()
                              .split(" ")
                              .join("-")}`}
                            onClick={() => {
                              this.leave();
                              this.toggleMainMenu();
                            }}
                          >
                            {subcat}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ) : i === 2 ? (
                  ""
                ) : (
                  <li className="col-sm-3" key={i}>
                    <ul>
                      <li className="dropdown-header">{cat}</li>
                      {this.props.stairCategories[cat].map((subcat, i) => (
                        <li key={i}>
                          <Link
                            to={`/${cat
                              .toLowerCase()
                              .split(" ")
                              .join("-")}/${subcat
                              .toLowerCase()
                              .split(" ")
                              .join("-")}`}
                            onClick={() => {
                              this.leave();
                              this.toggleMainMenu();
                            }}
                          >
                            {subcat}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                )
              )}
            </div>
          </div>
        )}
        {this.props.doorCategories[
          Object.keys(this.props.doorCategories)[0]
        ] && (
          <div
            className="dropdown-menu container-fluid doors"
            onMouseLeave={this.leave}
            aria-labelledby="dropdownDoors"
          >
            <div className="row">
              {Object.keys(this.props.doorCategories).map((cat, i) => (
                <li className="col-sm-3" key={i}>
                  <ul>
                    <li className="dropdown-header">{cat}</li>
                    {this.props.doorCategories[cat].map((subcat, i) => (
                      <li key={i}>
                        <Link
                          to={`/${cat
                            .toLowerCase()
                            .split(" ")
                            .join("-")}/${subcat
                            .toLowerCase()
                            .split(" ")
                            .join("-")}`}
                          onClick={() => {
                            this.leave();
                            this.toggleMainMenu();
                          }}
                        >
                          {subcat}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </div>
          </div>
        )}
        {this.props.mobilaCategories[
          Object.keys(this.props.mobilaCategories)[0]
        ] && (
          <div
            className="dropdown-menu container-fluid mobila"
            onMouseLeave={this.leave}
            aria-labelledby="dropdownMobila"
          >
            <div className="row">
              {Object.keys(this.props.mobilaCategories).map((cat, i) => (
                <li className="col-sm-3" key={i}>
                  <ul>
                    <li className="dropdown-header">{cat}</li>
                    {this.props.mobilaCategories[cat].map((subcat, i) => (
                      <li key={i}>
                        <Link
                          to={`/${cat
                            .toLowerCase()
                            .split(" ")
                            .join("-")}/${subcat
                            .toLowerCase()
                            .split(" ")
                            .join("-")}`}
                          onClick={() => {
                            this.leave();
                            this.toggleMainMenu();
                          }}
                        >
                          {subcat}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </div>
          </div>
        )}
      </NavbarWrapper>
    );
  }
}

Navbar.propTypes = {
  stairCategories: PropTypes.object,
  doorCategories: PropTypes.object,
  mobilaCategories: PropTypes.object,
  categoriesByTypes: PropTypes.object,
  getCatAndSubcat: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  stairCategories: state.category.stairCategories,
  doorCategories: state.category.doorCategories,
  mobilaCategories: state.category.mobilaCategories,
  categoriesByTypes: state.category.categoriesByTypes,
});

export default withRouter(
  connect(mapStateToProps, { getCatAndSubcat })(Navbar)
);
