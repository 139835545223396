import {
  // GET_STAIRS,
  // GET_PHOTO,
  GET_PRODUCTS_CAT_SUBCAT,
  GET_ALL_PRODUCTS,
  // ADD_PRODUCT
} from "../actions/types";

const initialState = {
  // storeStairs: [],
  // storePhotos: [],
  selectedProducts: [],
  loading: true,
  products: [],
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    // case GET_STAIRS:
    //   return {
    //     ...state,
    //     storeStairs: action.payload,
    //     loading: false,
    //   };
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    // case GET_PHOTO:
    //   return {
    //     ...state,
    //     storePhotos: action.payload,
    //     loading: false,
    //   };
    case GET_PRODUCTS_CAT_SUBCAT:
      return {
        ...state,
        selectedProducts: action.payload,
        loading: false,
      };
    // case ADD_PRODUCT:
    //   // localStorage.setItem('token', action.payload.token);
    //   console.log('state', state);
    //   console.log('action.payload', action.payload);
    //   return {
    //     ...state,
    //     // fill the user with what is in payload
    //     ...action.payload,
    //     // isAuthenticated: true,
    //     // loading: false
    //   };

    default:
      return state;
  }
};

export default productReducer;
