import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getProductsByCatAndSubcat } from "../../store/actions/product";
import SectionTitle from "./SectionTitle";
import { Redirect } from "react-router-dom";
import Spinner from "../layout/Spinner";
import ScaraCard from "./StairCard";

const Product = ({
  history,
  onGetSelectedProducts,
  categories,
  subcategories,
  product: { loading, selectedProducts },
}) => {
  const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
  const categoryName = capitalize(
    history.location.pathname.split("/")[1].split("-").join(" ")
  );
  const category = categories.find((cat) => cat.name === categoryName);
  const subcategoryName = capitalize(
    history.location.pathname.split("/")[2].split("-").join(" ")
  );
  const subcategory = subcategories.find(
    (subcat) => subcat.name === subcategoryName
  );

  const func = () => console.log("");

  useEffect(() => {
    onGetSelectedProducts(category, subcategory);
  }, [onGetSelectedProducts, category, subcategory]);

  return loading ? (
    <Spinner />
  ) : selectedProducts === null ? (
    <Redirect to="/notfound" />
  ) : (
    <section id="stairs" className="stairs py-5">
      <div className={`${window.innerWidth > 411 ? "container" : ""} "mb-5"`}>
        <SectionTitle
          sectionTitle={categoryName}
          sectionSubtitle={subcategoryName}
          price={categoryName === "Usi interioare" ? true : false}
        />
        <div id="stairs-items" className="row stairs-items">
          {selectedProducts.map((product, i) => {
            return (
              <ScaraCard
                key={i}
                index={i + 1}
                stairTitle={product.title}
                item={product}
                url="product"
                onClickData={func}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

Product.propTypes = {
  history: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  categories: PropTypes.array,
  subcategories: PropTypes.array,
};

const mapStateToProps = (state) => ({
  categories: state.category.storeCategories,
  subcategories: state.category.storeSubcategories,
  product: state.product,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSelectedProducts: (cat, subcat) =>
    dispatch(getProductsByCatAndSubcat(cat, subcat)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
