import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Slider from "react-animated-slider";

import { API } from "../../../config";

import "react-animated-slider/build/horizontal.css";
import "./AnimatedSlider.css";
import "./style.css";

class AnimatedSlider extends Component {
  sliderRef;

  constructor(props) {
    super(props);
    this.pickRandomProducts = (products) =>
      [...Array(6)].map(
        () => products.splice(Math.floor(Math.random() * products.length), 1)[0]
      );
  }

  componentDidMount() {
    this.timerID = setInterval(this.sliderRef.next, 5000);
  }

  componentWillUnmount() {
    if (this.timerID) {
      clearInterval(this.timerID);
    }
  }
  render() {
    return (
      <Slider
        ref={(ref) => (this.sliderRef = ref)}
        className="slider-wrapper row"
        slideIndex={1}
        touchDisabled={true}
      >
        {this.pickRandomProducts(this.props.products).map((item, index) => (
          <div
            key={index}
            className="slider-content"
            style={{
              background: `url(${API}/photo/${item._id}) center/cover no-repeat`,
            }}
          >
            <div className="inner">
              <h2>{item.category.name}</h2>
              <h3>{item.subcategory.name}</h3>
            </div>
          </div>
        ))}
      </Slider>
    );
  }
}

AnimatedSlider.propTypes = {
  products: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  products: state.product.products,
});

export default connect(mapStateToProps)(AnimatedSlider);
