import React from "react";
import { Link } from "react-router-dom";
import { FaWhatsapp, FaFacebookF } from "react-icons/fa";

export const Footer = () => {
  const date = new Date().getFullYear();
  const bizooHeight = window.innerWidth > 1008 ? 230 : 380;

  return (
    <footer className="page-footer footer">
      <div
        className="bizoo-widget"
        data-template-id="3"
        data-business-id="1511621"
        data-box-type="8"
        data-style-height={bizooHeight}
        data-style-width="1190px"
        data-theme="dark"
        style={{ position: "relative" }}
      ></div>
      <div
        className="footer-copyright py-3 row text-center text-lg-left"
        style={{
          borderBottom: "1px solid gray",
        }}
      >
        <div className="col-10 offset-1">
          <span>Contacteaza-ne!&nbsp;&nbsp;</span>
          <a
            href="https://wa.link/ann60n"
            className="cherry-link m-2 social wa"
          >
            <FaWhatsapp />
          </a>
          <a
            href="https://www.facebook.com/proiectarescari"
            className="cherry-link m-2 social fb"
          >
            <FaFacebookF />
          </a>
        </div>
      </div>
      <div className="footer-copyright py-3 row ">
        <div className="col-10 mx-auto d-flex justify-content-between flex-column flex-lg-row">
          <div className="my-2 text-center text-lg-left">
            <Link className="cherry-link" to="/">
              Proiectare Scari
            </Link>{" "}
            &copy;{date} - Toate drepturile rezervate
          </div>
          <div className="mt-2 text-center text-lg-left d-flex d-sm-block flex-column flex-sm-row">
            <Link
              to="/politica-de-confidentialitate"
              className="white-link mr-sm-2"
            >
              Politica de confidentialitate
            </Link>
            {`${window.innerWidth > 768 ? "|" : ""}`}
            <Link
              to="/politica-cookies"
              className="white-link mt-3 mt-sm-0 ml-sm-2"
            >
              Politica cookies
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
