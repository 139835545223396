import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AnimatedSlider from "./slider/AnimatedSlider";
import ScaraCard from "../products/StairCard";
import SectionTitle from "../products/SectionTitle";
import Spinner from "../layout/Spinner";
import { getAllProducts } from "../../store/actions/product";

const Home = ({ onGetAllProducts, products, types }) => {
  useEffect(() => {
    onGetAllProducts();
  }, [onGetAllProducts]);

  return products.length === 0 ? (
    <Spinner />
  ) : (
    <Fragment>
      <AnimatedSlider />
      <section id="stairs" className="stairs py-5">
        <div className="container mb-5">
          {types.map((type, index) => (
            <Fragment key={index}>
              <SectionTitle
                sectionTitle={type}
                price={type === "usi din lemn" ? true : false}
              />
              <div id="stairs-items" className="row stairs-items">
                {products
                  .filter((product) => product.category.type === type)
                  .slice(0, 6)
                  .map((prod, i) => (
                    <ScaraCard key={i} stairTitle={prod.title} item={prod} />
                  ))}
              </div>
            </Fragment>
          ))}
        </div>
      </section>
    </Fragment>
  );
};

Home.propTypes = {
  products: PropTypes.array,
  types: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  products: state.product.products,
  types: state.category.types,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAllProducts: () => dispatch(getAllProducts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
