import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import StairCard from "../products/StairCard";

export const ImageSelector = ({
  tipSelectie,
  configuration: { configImages },
  onSelectedPhotoChange,
  stateValue,
  beton,
}) => {
  return (
    <div className="row">
      {configImages
        .filter((image) => image.tipSelectie === tipSelectie)
        .map((image, i) => {
          let showImage = image.beton ? (beton ? false : true) : true;
          return (
            showImage && (
              <StairCard
                stairTitle={image.titlu}
                key={i}
                item={image}
                config={true}
                selected={stateValue === image.titlu}
                onClickData={() =>
                  onSelectedPhotoChange(image.tipSelectie, image.titlu)
                }
              />
            )
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  configuration: state.configuration,
});

ImageSelector.propTypes = {
  configuration: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ImageSelector);
