import React, { useEffect } from "react";
import SectionTitle from "../products/SectionTitle";

export const PoliticaCookies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mx-lg-5 my-5">
      <SectionTitle sectionTitle="Politica cookies" />
      <div className="my-5 mx-lg-5 px-md-5 pb-3 row">
        <div className="col-12 mx-auto text-justify mb-5">
          <br />
          <h5>
            <strong> Modul in care utilizam cookie-uri</strong>
          </h5>
          <p>
            Utilizam cookie-uri pentru a monitoriza activitatea dumneavoastra.
            Cookie-urile noastre nu retin date cu caracter personal cum ar fi
            numele sau detaliile bancare. Acestea doar ajuta la a evalua
            informatiile privind navigarea facuta. Tratam cu seriozitate
            protectia datelor tale cu caracter personal – intotdeauna
            monitorizam si imbunatatim modul in care le putem proteja.
          </p>
          <p>
            Cookie-urile utilizate pe site-ul nostru se incadreaza in categoria
            de cookie-uri folosite pentru performanta – acestea sunt utilizate
            pentru a analiza modul in care functioneaza site-ul nostru si cum îl
            putem imbunatati, precum si la monitorizarea traficului.
          </p>
          <p>
            Cookie-urile permit o mai bună experienta de vizitare a site-ului
            nostru. Folosim cookie-uri pentru:
          </p>
          <p className="ml-3">
            • Identificarea traseului de navigare al utilizatorilor pe site
          </p>
          <p className="ml-3">
            • Analiza vizitatorilor, in vederea imbunatatirii experientei pe
            site. Cu ajutorul Statcounter, colectam date despre vizitele pe
            site-ul nostru
          </p>
          <br />
          <h5>
            <strong>Lista completa a cookie-urilor:</strong>
          </h5>
          <p className="ml-3">• sc_is_visitor_unique</p>
          <p className="ml-3">• is_unique</p>
          <p className="ml-3">• is_unique_1</p>
          <p className="ml-3">• is_visitor_unique</p>
          <p>
            Toate acestea colecteaza date anonime cu privire la vizitele pe
            site-ul nostru, paginile vizualizate, locatie, device utilizat,
            adresa IP.
          </p>
          <br />
          <h5>
            <strong>Cookie-uri terte</strong>
          </h5>
          <p>
            Cookie-urile primare provin de pe acelasi domeniu ca si site-ul pe
            care il vizitati in momentul respectiv (in cazul de fata,
            www.proiectarescari.com). Cookie-urile terte provin dintr-un domeniu
            diferit de site-ul pe care il vizitezi. De exemplu, atunci cand
            vizitezi site-ul nostru, o parte dintre cookie-uri vor avea ca
            domeniu www.statcounter.com.
          </p>
          <br />
          <h5>
            <strong>Controlul cookie-urilor</strong>
          </h5>
          <p>
            Daca doresti sa afli cum sa opresti site-urile web să utilizeze
            cookie-uri pentru a-ai aminti ce site-uri vizitati, selecteaza
            browser-ul din listă. Aceasta te va duce pe pagina respectivului
            browser, unde poti administra cookie-urile si datele privind
            site-ul:
          </p>
          <div className="d-flex justify-content-around">
            <a
              href="https://support.google.com/chrome/answer/95647?hl=en-GB"
              target="_blank"
              rel="noopener noreferrer"
              className="article-link m-2"
            >
              <strong>Chrome</strong>
            </a>
            <a
              href="https://www.mozilla.org/en-US/firefox/dnt/"
              target="_blank"
              rel="noopener noreferrer"
              className="article-link m-2"
            >
              <strong>Firefox</strong>
            </a>
            <a
              href="https://support.microsoft.com/en-us/microsoft-edge/view-cookies-in-microsoft-edge-a7d95376-f2cd-8e4a-25dc-1de753474879"
              target="_blank"
              rel="noopener noreferrer"
              className="article-link m-2"
            >
              <strong>Microsoft Edge</strong>
            </a>
            <a
              href="https://support.apple.com/kb/PH19214?viewlocale=en_US&locale=en_US"
              target="_blank"
              rel="noopener noreferrer"
              className="article-link m-2"
            >
              <strong>Safari</strong>
            </a>
          </div>

          <br />
          <p>
            <em>
              Daca iti modifici setarile privind cookie-urile din browser,
              experienta ta cu noi nu va fi monitorizata.
            </em>
          </p>
        </div>
      </div>
    </div>
  );
};
