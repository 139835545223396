import styled from "styled-components";

export const NavbarWrapper = styled.header`
  .navbar {
    position: relative;
  }
  .nav-item {
    /* border: 1px solid; */
  }
  .nav-link {
    padding: 0 1rem 1rem 1rem;
    display: inline;
    /* border: 1px solid green !important; */
  }
  .main-menu {
    display: ${(props) => (props.mainMenu ? "block" : "none")};
  }
  button.navbar-toggler:hover {
    background: none !important;
  }
  .dropdown-content {
    /* display: none; */
    position: absolute;
    /* background-color: #f9f9f9; */
    background-color: #f0f0f0;
    width: 100%;
    /* height: 100%; */
    border: 2px solid red;
    left: 0;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  /* .dropdown-content .header {
    background: red;
    padding: 16px;
    color: white;
  } */
  /* .dropdown:hover .dropdown-content {
    display: block;
  } */
  /* Create three equal columns that floats next to each other */
  /* .column {
    float: left;
    width: 33.33%;
    padding: 10px;
    background-color: #ccc;
    height: 250px;
  } */
  .column a {
    float: none;
    color: black;
    padding: 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  .column a:hover {
    background-color: #ddd;
  }
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .dropdown-menu {
    overflow: hidden;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100% !important;
    /* height: 100%; */
    /* width: 100%; */
    background-color: #ccc !important;
    border: none !important;
    /* min-width: 400px !important; */
  }
  .stairs {
    display: ${(props) => (props.showStairs ? "block" : "none")};
  }
  .doors {
    display: ${(props) => (props.showDoors ? "block" : "none")};
  }
  .mobila {
    display: ${(props) => (props.showMobila ? "block" : "none")};
  }
  .dropdown-item {
    padding: 0 20px;
  }
  /* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
  /* @media(max-width: 600px) {
    .column {
      width: 100%;
      height: auto;
    }
  } */
  h1,
  .h1 {
    font-size: 36px;
    text-align: center;
    font-size: 5em;
    color: #404041;
  }
  .navbar-nav li .dropdown-menu {
    margin-top: 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .navbar-default .navbar-nav li a {
    width: 200px;
    font-weight: bold;
  }
  .mega-dropdown {
    position: static !important;
    width: 100%;
  }
  .dropdown-menu {
    padding: 20px 0px;
    width: 100%;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  .dropdown-menu:before {
    content: "";
    border-bottom: 15px solid #fff;
    border-right: 17px solid transparent;
    border-left: 17px solid transparent;
    position: absolute;
    top: -15px;
    left: 285px;
    z-index: 10;
  }
  .dropdown-menu:after {
    content: "";
    border-bottom: 17px solid #ccc;
    border-right: 19px solid transparent;
    border-left: 19px solid transparent;
    position: absolute;
    top: -17px;
    left: 283px;
    z-index: 8;
  }
  .dropdown-menu li ul {
    padding: 0;
    margin: 0;
  }
  .dropdown-menu li ul li {
    list-style: none;
  }
  .dropdown-menu li ul li a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: var(--mainBlack);
    white-space: normal;
    transition: all 0.3s ease-in-out;
    margin: 2px 0 2px 9px;
    width: 80%;
  }
  .dropdown-menu li ul li a:hover,
  .dropdown-menu li ul li a:focus {
    text-decoration: none;
    color: #444;
    background-color: #f5f5f5;
    padding: 0.5rem 0rem 0.5rem 2.5rem;
    /* width: 80%; */
    margin: 2px 0 2px 9px;
  }
  .dropdown-menu .dropdown-header {
    color: var(--mainCherry);
    font-size: 18px;
    font-weight: bold;
    white-space: pre-wrap;
  }
  .dropdown-menu .divider {
    height: 1px;
    margin: 9px 0 9px 9px;
    overflow: hidden;
    background-color: #e5e5e5;
    width: 80%;
  }
`;
