import React, { useEffect } from "react";
import SectionTitle from "../products/SectionTitle";

export const PoliticaConfidentialitate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mx-lg-5 my-5">
      <SectionTitle sectionTitle="Politica de confidentialitate" />
      <div className="my-5 mx-lg-5 px-md-5 pb-3 row">
        <div className="col-12 mx-auto text-justify mb-5">
          <br />
          <p>
            Va multumim ca vizitati site-ul nostru. Va rugam sa acordati cateva
            minute pentru lecturarea acestei Politici de confidentialitate. Cand
            vizitati site-ul www.proiectarescari.com, cand ne contactati sau
            cand apelati la serviciile noastre, ne incredintati datele
            dumneavoastra personale. Scopul acestei Politici de
            confidentialitate este sa va explice ce date prelucram, de ce le
            prelucram si ce facem cu ele. Tratam cu cea mai mare seriozitate
            confidentialitatea datelor dumneavoastra si nu vindem niciodata
            liste sau adrese de e-mail. Facem tot posibilul sa stocam datele cu
            caracter personal in siguranta si sa procesam cu atentie
            informatiile pe care ni le transmiteti. Nu oferim informatii unor
            terte parti fara a va informa. In general, intentia noastra este sa
            colectam numai datele cu caracter personal care sunt furnizate in
            mod voluntar si care sunt minim necesare pentru a putea oferi
            informatii privind serviciile si produsele noastre. Aceasta Politica
            se aplica: utilizatorilor website-ului nostru.
          </p>
          <p>
            Prin navigarea pe site-ul www.proiectarescari.com, prin contactarea
            noastra prin formular, prin optiunea “Contact” sau “Configurator”,
            declarati ca ati fost informati in mod adecvat asupra prelucrarii
            datelor prin parcurgerea acestui document, denumit “Politica de
            confidentialitate” si ca va dati consimtamantul pentru prelucrarea
            lor in termenii si conditiile expuse mai jos.
          </p>
          <br />
          <h5>DEFINITII:</h5>
          <p>
            - “www.proiectarescari.com” (sau ”Site-ul”) este un site de
            prezentare al ANTOHE IONEL INTREPRINDERE INDIVIDUALA detinut si
            administrat de catre ANTOHE IONEL INTREPRINDERE INDIVIDUALA cu
            sediul in Brasov Str. Egretei, Nr. 6, Bl. B6, Ap. 63, Biroul 1,
            Brașov, telefon (004) 0745 258 841, C.I.F. 42349392, Numar de
            inregistrare in Registrul Comertului F08/215/2020;
          </p>
          <p>
            - “operator de date cu caracter personal” poate fi o persoana fizica
            sau o persoana juridica ce decide asupra naturii si limitelor
            prelucrarii de date. Potrivit Regulamentului (UE) 679/2016
            (aplicabil in Romania din 25 mai 2018), ANTOHE IONEL INTREPRINDERE
            INDIVIDUALA este operator de date cu caracter personal;
          </p>
          <p>
            - “GDPR” este Regulamentul (UE) 679/2016 privind protectia
            persoanelor fizice in ceea ce priveste prelucrarea datelor cu
            caracter personal si privind libera circulatie a acestor date si de
            abrogare a Directivei 95/46/CE;
          </p>
          <p>
            - “date cu caracter personal” inseamna orice informatii privind o
            persoana fizica identificata sau identificabila (“persoana vizata”);
          </p>
          <p>
            - “persoana vizata” este chiar persoana ale carei date cu caracter
            personal sunt supuse prelucrarii si reprezinta o persoana fizica
            identificata sau identificabila. O persoana fizica identificabila
            este o persoana care poate fi identificata, direct sau indirect, in
            special prin referire la un element de identificare, cum ar fi un
            nume, un numar de identificare, date de localizare, un identificator
            online, sau la unul sau mai multe elemente specifice, proprii
            identitatii sale fizice, fiziologice, genetice, psihice, economice,
            culturale sau sociale.
          </p>
          <p>
            - “prelucrarea datelor cu caracter personal” reprezinta orice
            operatiune sau set de operatiuni efectuate asupra datelor cu
            caracter personal sau asupra seturilor de date cu caracter personal,
            cu sau fara utilizarea de mijloace automatizate, cum ar fi
            colectarea, inregistrarea, organizarea, structurarea, stocarea,
            adaptarea sau modificarea, extragerea, consultarea, utilizarea,
            divulgarea prin transmitere, diseminarea sau punerea la dispozitie
            in orice alt mod, alinierea sau combinarea, restrictionarea,
            stergerea sau distrugerea.
          </p>
          <br />
          <h5>PRINCIPII:</h5>
          <p>
            Politica de protejare a datelor in ANTOHE IONEL INTREPRINDERE
            INDIVIDUALA se bazeaza pe urmatoarele principii de protectie a
            datelor:
          </p>
          <p>
            1. Procesarea datelor personale se va face intr-o maniera legala,
            corecta si transparenta;
          </p>
          <p>
            2. Colectarea datelor personale se va face numai in scopuri
            specificate, explicite si legitime si datele nu vor fi procesate mai
            departe intr-o maniera incompatibila cu acele scopuri;
          </p>
          <p>
            3. Colectarea datelor personale va fi adecvata, relevanta si
            limitata la informatiile necesare scopului procesarii;
          </p>
          <p>
            4. Datele personale vor fi acurate si, acolo unde este necesar,
            actualizate;
          </p>
          <p>
            5. Se vor lua toate masurile necesare pentru a se asigura ca datele
            incorecte sunt sterse sau corectate fara intarziere;
          </p>
          <p>
            6. Datele personale vor fi pastrate intr-o forma ce permite
            identificarea persoanei vizate si pentru o perioada nu mai lunga
            decat cea in care datele personale sunt procesate;
          </p>
          <p>
            7. Toate datele personale vor fi pastrate confidential si stocate
            intr-o maniera ce asigura securitatea necesara;
          </p>
          <p>
            8. Datele personale nu vor fi distribuite persoanelor terte decat in
            cazul in care este necesar in scopul oferirii de servicii conform
            acordurilor;
          </p>
          <p>
            9. Persoanele vizate au dreptul de a solicita accesul la datele
            personale, rectificarea si stergerea acestora, impotrivirea sau
            restrictionarea de la procesarea datelor cat si de la dreptul
            portabilitatii datelor.
          </p>
          <br />
          <h5>SCHIMBARI:</h5>
          <p>
            ANTOHE IONEL INTREPRINDERE INDIVIDUALA poate schimba aceasta
            Politica de confidentialitate in orice moment. Toate actualizarile
            si modificarile prezentei Politici sunt valabile imediat dupa
            notificare, pe care o vom realiza prin afisare pe site.
          </p>
          <br />
          <h5>INTREBARI SI SOLICITARI:</h5>
          <p>
            Daca aveti intrebari sau nelamuriri cu privire la prelucrarea
            datelor personale sau daca doriti sa va exercitati drepturile legale
            in legatura cu datele pe care le detinem sau daca aveti ingrijorari
            cu privire la modul in care tratam orice problema de
            confidentialitate, ne puteti scrie la adresa de e-mail:
            proiectarescari@yahoo.com.
          </p>
          <br />
          <h5>INFORMATII COLECTATE:</h5>
          <p>
            In general, nu vi se cere sa furnizati nicio data cu caracter
            personal cand vizitati site-ul nostru, dar v-am putea solicita sa
            oferiti anumite asemenea date pentru a putea primi informatii
            suplimentare despre serviciile noastre. Daca doriti sa nu mai
            beneficiati de un serviciu sau comunicare, vom incerca sa eliminam
            datele dumneavoastra cu caracter personal imediat, desi este posibil
            sa va solicitam informatii suplimentare inainte de a va putea
            procesa cererea. Informatiile colectate de noi se refera, in
            principal, la:
          </p>
          <p>&nbsp; 1. Informatiile furnizate voluntar:</p>
          <p>
            Prin trimiterea mesajelor de contact, a configuratoarelor pentru
            scari sau prin contactarea noastra prin alte metode (telefonic, sms,
            WhatsApp, etc) exista posibilitatea sa ne transmiteti urmatoarele
            informatii: nume, prenume, adresa e-mail, telefon. Cand utilizati
            formularul de pe site, cand ne contactati prin telefon sau prin
            e-mail sau cand comunicati cu noi in orice mod, ne oferiti in mod
            voluntar informatti pe care noi le prelucram. Aceste informatii
            includ numele, prenumele, adresa de e-mail si numarul de telefon.
          </p>
          <p>&nbsp; 2. Informatii colectate automat:</p>
          <p>
            Atunci cand navigati pe site-ul nostru, este posibil sa colectam
            informatii despre vizita dumneavoastra pe site. Aceste informatii
            pot include adresa IP, sistemul de operare, browserul, activitatea
            de navigare si alte informatii despre modul in care ati
            interactionat cu site-ul. Putem colecta aceste informatii prin
            folosirea cookie-urilor sau a altor tehnologii similare. Toate
            formularele incluse in acest site stocheaza datele cu caracter
            personal in baza de date si le transmit prin intermediul serviciilor
            de email catre proprietar. Aceste date transmise prin email pot fi
            stocate conform legislatiei in vigoare de terte parti precum
            furnizorii de legaturi internet si stocare baze de date, retele
            locale, gazduire email. Alte metode de colectare a datelor personale
            nu mai exista in acest site.
          </p>
          <p>
            Datele cu caracter personal colectate prin intermediul acestui site
            pot fi stocate in sisteme informatice interne detinute de proprietar
            in vederea elaborarii unei oferte comerciale sau pentru indeplirea
            unor obligatii legale/contractuale. ANTOHE IONEL INTREPRINDERE
            INDIVIDUALA se obliga a gestiona datele stocate in conditii de
            siguranta si numai pentru scopurile comerciale specificate.
          </p>
          <br />
          <h5>
            TEMEIUL LEGAL PENTRU PRELUCRAREA DATELOR CU CARACTER PERSONAL:
          </h5>
          <p>
            In ceea ce priveste datele pe care ni le oferiti voluntar prin
            completarea si trimiterea formularului aferent sau prin contactarea
            noastra in orice mod, temeiul legal este ”luarea unor masuri la
            cererea persoanei vizate inaintea incheierii unui contract” (art. 5
            alin. 2 lit. (a) din Legea nr. 677/2001) si ”pentru a face demersuri
            la cererea persoanei vizate inainte de incheierea unui contract”
            (art. 6 alin. (1) lit. b din Regulamentul (UE) 679/2016).
          </p>
          <p>
            Atat potrivit legislatiei actuale, cat si potrivit GDPR nu este
            necesara solicitarea consimtamantului dumneavoastra in situatia in
            care prelucrarea este necesara pentru efectuarea de demersuri pentru
            incheierea unui contract, indeplinirea unei obligatii legale sau
            interesul legitim.
          </p>
          <p>
            In privinta datelor pe care le colectam automat prin folosirea
            cookie-urilor sau a altor tehnologii similare, temeiul pentru
            prelucrare este consimtamantul. Odata cu accesarea site-ului, va
            dati in mod valabil consimtamantul asupra prelucrarii.
            Consimtamantul nu este o preconditie pentru stabilirea unei relatii
            comerciale si nu creeaza niciun fel de obligatii contractuale.
          </p>
          <br />
          <h5>SCOPUL COLECTARII DATELOR CU CARACTER PERSONAL:</h5>
          <p>
            Datele dumneavoastra sunt colectate doar in urmatoarele scopuri:
          </p>
          <p>
            - pentru a face demersurile necesare in vederea incheierii unui
            contract;
          </p>
          <p>- pentru a va raspunde la intrebari si solicitari;</p>
          <p>- pentru a ne apara impotriva atacurilor cibernetice.</p>
          <br />
          <h5>SECURITATEA SI INTEGRITATEA DATELOR:</h5>
          <p>
            Am implementat politici si proceduri de securitate rezonabile pentru
            a proteja informatiile cu caracter personal impotriva pierderii,
            utilizarii necorespunzatoare, modificarii sau distrugerii
            neautorizate a acestora, insa, cu toate eforturile depuse de noi, nu
            se poate garanta o securitate absoluta impotriva tuturor riscurilor.
            In cea mai mare masura posibila, accesul la datele dumneavoastra cu
            caracter personal este strict limitat la acele persoane care au
            nevoie sa le cunoasca. Persoanele care au acces la aceste date
            trebuie sa mentina confidentialitatea acestora. De asemenea, facem
            eforturi rezonabile pentru a pastra informatiile cu caracter
            personal doar atata timp cat este necesar pentru a ne conforma
            cererii din partea unei persoane sau pana cand persoana respectiva
            solicita eliminarea lor.
          </p>
          <h5>TIMPUL DE STOCARE AL DATELOR:</h5>
          <p>
            Stocam in sistemele noastre datele cu caracter personal pe care le
            colectam intr-un mod care permite identificarea persoanelor vizate
            limitat la perioada de timp pentru care este necesar in lumina
            scopurilor pentru care au fost colectate datele sau pentru care
            aceste date sunt prelucrate ulterior. Aceasta perioada este in
            general de maxim 30 de zile calendaristice.
          </p>
          <p>
            - Determinam aceasta perioada specifica de timp luand in
            considerare:
          </p>
          <p>
            - Necesitatea de a pastra datele cu caracter personal stocate pentru
            a oferi serviciile noastre;
          </p>
          <p>
            - Pentru a proteja interesele legitime ale Operatorului, in
            conformitate cu prezenta Politica;
          </p>
          <p>
            - Existenta obligatiilor legale specifice care fac necesara
            prelucrarea si stocarea aferenta pentru o anumita perioada de timp.
          </p>
          <h5>DEZVALUIREA INFORMATIILOR:</h5>
          <p>
            Va asiguram ca nu vom dezvalui informatiile dumneavoastra catre
            terte parti, pentru a fi utilizate in propriile scopuri de marketing
            sau comerciale ale acestora, fara consimtamantul dumneavoastra.
            Totusi, este posibil sa dezvaluim informatiile dumneavoastra catre
            urmatoarele entitati:
          </p>
          <p>
            Furnizori de servicii. Putem dezvalui informatiile altor companii
            care ne furnizeaza servicii si actioneaza in calitate de persoane
            imputernicite, cum ar fi societatile de contabilitate, de curierat,
            posta sau alti colaboratori. Aceste entitati sunt selectate cu o
            grija deosebita pentru a ne asigura ca indeplinesc cerintele
            specifice in materie de protectie a datelor cu caracter personal.
            Aceste entiati au o capacitate limitata de a utiliza informatiile
            dumneavoastra in alte scopuri decat cel de a ne furniza servicii;
          </p>
          <p>
            Instante de judecata, parchete sau alte autoritati publice pentru a
            ne conforma legii sau drept raspuns la o procedura legala
            obligatorie (cum ar fi un mandat de perchezitie sau o hotarare
            judecatoreasca);
          </p>
          <p>
            Altor parti cu consimtamantul sau la instructiunile dumneavoastra.
            In afara dezvaluirilor descrise in prezenta Politica de
            confidentialitate, este posibil sa transmitem informatiile tertilor
            carora consimtiti sau solicitati sa efectuam o asemenea dezvaluire.
          </p>
          <br />
          <h5>DREPTURILE PERSOANEI VIZATE:</h5>
          <p>&bull; Dreptul de retragere a consimtamantului;</p>
          <p className="ml-3">
            &nbsp;- atunci cand prelucrarea se bazeaza pe consimtamant, va
            puteti retrage consimtamantul in orice moment si in mod gratuit prin
            transmiterea unui e-mail pe adresa proiectarescari@yahoo.com cu
            subiectul “Retragere consimtamant prelucrare date cu caracter
            personal”.
          </p>
          <p>
            &bull; Dreptul la informare cu privire la cum sunt colectate si
            folosite datele si dreptul de acces la aceste date;
          </p>
          <p>
            &bull; Dreptul de a depune o plangere in fata unei autoritati de
            supraveghere;
          </p>
          <p>&bull; Dreptul de a te adresa justitie;</p>
          <p>&bull; Dreptul de acces;</p>
          <p className="ml-3">
            &nbsp;- veti dreptul de a obtine din partea noastra o confirmare ca
            se prelucreaza sau nu date cu caracter personal care va privesc si,
            in caz afirmativ, aveti dreptul de acces la datele respective.
          </p>
          <p>&bull; Dreptul la rectificare</p>
          <p className="ml-3">
            &nbsp;- veti dreptul de a obtine din partea noastra, fara intarzieri
            nejustificate, rectificarea datelor cu caracter personal inexacte
            care va privesc. Tinandu-se seama de scopurile in care au fost
            prelucrate datele, aveti dreptul de a obtine completarea datelor cu
            caracter personal care sunt incomplete, inclusiv prin furnizarea
            unei declaratii suplimentare.
          </p>
          <p>&bull; Dreptul la stergerea datelor (“dreptul de a fi uitat”)</p>
          <p className="ml-3">
            &nbsp;- in situatiile in care (i) datele nu mai sunt necesare pentru
            indeplinirea scopurilor, (ii) s-a retras consimtamantul si nu exista
            un alt temei juridic pentru prelucrare, (iii) te opui prelucrarii si
            nu exista motive legitime care sa prevaleze in ceea ce priveste
            prelucrarea sau (iv) datele cu caracter personal au fost prelucrate
            ilegal, aveti dreptul de a obtine stergerea datelor care va privesc,
            fara intarzieri nejustificate.
          </p>
          <p>&bull; Dreptul la restrictionarea prelucrarii</p>
          <p className="ml-3">
            &nbsp;- veti dreptul de a obtine din partea noastra restrictionarea
            prelucrarii in cazul in care se aplica unul din urmatoarele cazuri:
          </p>
          <p className="ml-5">
            (i) contestati exactitatea datelor, pentru o perioada care ne
            permite noua sa verificam exactitatea datelor;
          </p>
          <p className="ml-5">
            (ii) prelucrarea este ilegala, iar dumneavoastra va opuneti
            stergerii datelor cu caracter personal, solicitand in schimb
            restrictionarea utilizarii lor;
          </p>
          <p className="ml-5">
            (iii) nu mai avem nevoie de datele cu caracter personal in scopul
            prelucrarii, dar dumneavoastra ni le solicitati pentru constatarea,
            exercitarea sau apararea unui drept in instanta;
          </p>
          <p className="ml-5">
            (iv) v-ati opus prelucrarii in conformitate cu articolul 21
            alineatul (1) din GDPR, pentru intervalul de timp in care se
            verifica daca drepturile noastre legitime prevaleaza asupra
            drepturilor dumneavoastra.
          </p>
          <p>&bull; Dreptul la portabilitatea datelor</p>{" "}
          <p>
            &nbsp;- aveti dreptul de a primi datele cu caracter personal care va
            privesc si pe care ni le-ati furnizat intr-un format structurat,
            utilizat in mod curent si care poate fi citit automat si aveti
            dreptul de a transmite aceste date altui operator, fara obstacole
            din partea noastra, in cazul in care:
          </p>
          <p className="ml-5">
            (i) prelucrarea se bazeaza pe consimtamant in temeiul articolului 6
            alineatul (1), litera (a) sau al articolului 9, alineatul (2),
            litera (a) din GDPR sau pe un contract in temeiul articolului 6
            alineatul (1) litera (b) din GDPR;
          </p>
          <p className="ml-5">
            (ii) prelucrarea este efectuata prin mijloace automate.
          </p>
          <p>
            &bull; Dreptul de a nu face obiectul unei decizii bazate exclusiv pe
            prelucrarea automata, inclusiv crearea de profiluri care produce
            efecte juridice care va privesc sau va afecteaza in mod similar
            intr-o masura semnificativa
          </p>
          <p>Nu aveti acest drept in cazul in care decizia:</p>
          <p className="ml-5">
            (i) este necesara pentru incheierea sau executarea unui contract
            intre dumneavoastra si un operator de date;
          </p>
          <p className="ml-5">
            (ii) este autorizata prin dreptul Uniunii sau dreptul intern care se
            aplica operatorului si care prevede, de asemenea, masuri
            corespunzatoare pentru protejarea drepturilor, libertatilor si
            intereselor legitime ale persoanei vizate;
          </p>
          <p className="ml-5">
            (iii) are la baza consimtamantul dumneavoastra explicit.
          </p>
          <p>
            Pentru a va exercita drepturile legale descrise mai sus, va rugam sa
            ne contactati la adresa de e-mail proiectarescari@yahoo.com sau la
            sediul nostru din Brasov, Str. Egretei, Nr. 6, Bl. B6, Ap. 63,
            Biroul 1, Brașov.
          </p>
          <br />
          <h5>LEGATURI CATRE ALTE SITE-URI:</h5>
          <p>
            Anumite pagini din site-ul nostru pot contine legaturi catre
            site-uri web ale unor terte parti. Nu sustinem si nu avem niciun
            control asupra practicilor de confidentialitate sau continutului
            acestor site-uri web. Va recomandam sa cititi cu atentie politicile
            de confidentialitate ale fiecarui site pe care il vizitati.
          </p>
        </div>
      </div>
    </div>
  );
};
