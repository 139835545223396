import { GET_CATEGORIES } from "../actions/types";

const initialState = {
  storeCategories: [], //toate categoriile complete
  storeSubcategories: [], //toate subcategoriile complete
  stairCategories: {}, //categorii de scari + subcategorii
  doorCategories: {}, //categorii de usi + subcategorii
  mobilaCategories: {}, //categorii de mobilier + subcategorii
  categoriesByTypes: {},
  types: [], //tipuri de produse
};

const categoryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CATEGORIES:
      return {
        ...state,
        storeCategories: payload.categories,
        storeSubcategories: payload.subcategories,
        stairCategories: payload.stairCategories,
        doorCategories: payload.doorCategories,
        mobilaCategories: payload.mobilaCategories,
        categoriesByTypes: payload.categoriesByTypes,
        types: payload.types,
      };
    default:
      return state;
  }
};

export default categoryReducer;
