import React from "react";

const SectionTitle = ({ sectionTitle, sectionSubtitle, price }) => {
  return (
    <div className="row my-2">
      <div className="col-12 mx-auto text-center">
        <h1 className="text-capitalize">
          <strong className="banner-title ">{sectionTitle}</strong>
        </h1>
        <h3>{sectionSubtitle}</h3>
        <div className="title-underline my-3 bg-secondary">
          <div className="title-underline__center"></div>
        </div>
      </div>
      {/* {price === true && (
        <h5>
          <br />
          <span className="text-danger">*</span>preturile afisate sunt
          <strong> orientative</strong> si variaza in functie de dimensiuni si
          localitatea montajului
        </h5>
      )} */}
    </div>
  );
};

export default SectionTitle;
