export const PRODUCT_ERROR = "PRODUCT_ERROR";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_PRODUCTS_CAT_SUBCAT = "GET_PRODUCTS_CAT_SUBCAT";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";

export const ADD_CONFIGURATOR = "ADD_CONFIGURATOR";
export const CONFIGURATOR_ERROR = "CONFIGURATOR_ERROR";
export const GET_CONFIG_IMAGES = "GET_CONFIG_IMAGES";
export const ADD_CONFIG_IMAGE = "ADD_CONFIG_IMAGE";

export const REMOVE_ALERT = "REMOVE_ALERT";
export const SET_ALERT = "SET_ALERT";

export const ADD_MESSAGE = "ADD_MESSAGE";
export const MESSAGE_ERROR = "MESSAGE_ERROR";
